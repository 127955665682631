import { isNil } from 'lodash';
import client from '../../apollo-client';
import { MeasureTypeEnum } from '../../enums/feelbat.enum';
import { SensorFilter } from '../../types/feelbat';
import {
  CLEAR_STARTING_CURVE,
  CREATE_STARTING_CURVE,
  FIND_ALL_USER_SENSOR,
  GET_FIRST_AND_LAST_VALUES,
  GET_METRICS_BY_SENSOR_ID,
  GET_METRICS_BY_SENSOR_ID_LIST,
  GET_SENSORS_IN_STOCK_AND_PROJECT,
  GET_SENSOR_BY_ID,
  GET_SENSOR_COUNT_BY_FAMILY,
  GET_SENSOR_METRICS_FORMATTED,
  RENEW_SIGFOX_SUBSCRIPTION,
  UPDATE_SENSOR,
} from './sensors.graphql';

export interface CreateSensorInput {
  measurement_interval?: number;
  sensor_type: string;
  image?: string;
  full_memory_handler_type?: number;
  vibration_alerte_type?: number;
  memory?: number;
  family: string;
  version_firmware?: string;
  thresholds_active_type?: number;
  id_device?: string;
  active: boolean;
  optional_name?: string;
  sigfox?: any; // Define the correct type
  project?: any; // Define the correct type
  module?: any; // Define the correct type
  existing?: any; // Define the correct type
}

export async function getSensorCountByFamilyService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSOR_COUNT_BY_FAMILY,
    });

    return data.sensorCountByFamily;
  } catch (error) {
    console.error(
      'An error occurred while fetching sensor count by family:',
      error
    );
    throw error;
  }
}

export async function findAllUserSensorService(
  sensorFilter?: SensorFilter
): Promise<any> {
  try {
    const { data } = await client.query({
      query: FIND_ALL_USER_SENSOR,
      variables: !isNil(sensorFilter) ? { sensorFilter } : {},
    });

    return data.findAllUserSensor;
  } catch (error) {
    console.error("An error occurred while user's sensors:", error);
    throw error;
  }
}

export async function getSensorsInStockAndProjectService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSORS_IN_STOCK_AND_PROJECT,
    });

    return data.sensorInStock;
  } catch (error) {
    console.error(
      'An error occurred while fetching sensors in stock and project:',
      error
    );
    throw error;
  }
}

export async function updateSensorService(
  updateSensorInput: any
): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_SENSOR,
      variables: { updateSensorInput },
    });

    return data.updateSensor;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}

export async function getSensorByIdService(id: string): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSOR_BY_ID,
      variables: { id },
    });

    return data.sensor;
  } catch (error) {
    console.error('An error occurred while fetching the sensor:', error);
    throw error;
  }
}

export async function getMetricsBySensorIdService(
  sensorId: string,
  startRange?: string,
  endRange?: string,
  fieldType?: MeasureTypeEnum[]
): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_METRICS_BY_SENSOR_ID,
      variables: { sensorId, startRange, endRange, fieldType },
    });

    return data.getMetricsBySensorId;
  } catch (error) {
    console.error(
      'An error occurred while fetching metrics by sensor ID:',
      error
    );
    throw error;
  }
}

export async function getMetricsBySensorsIdListService(
  sensorIdList: string[],
  startRange?: string | null,
  endRange?: string,
  fieldType?: MeasureTypeEnum[]
): Promise<any> {
  try {
    const getMetricsBySensorsIdListInput = {
      sensorIdList,
      startRange,
      endRange,
      fieldType,
    };

    const { data } = await client.query({
      query: GET_METRICS_BY_SENSOR_ID_LIST,
      variables: { getMetricsBySensorsIdListInput },
      fetchPolicy: 'network-only',
    });

    return data.getMetricsBySensorsIdList;
  } catch (error) {
    console.error(
      'An error occurred while fetching metrics by sensor ID list:',
      error
    );
  }
}

/**
 * Fetches and returns formatted metrics for specified sensors within a given time range.
 *
 * This function queries the server for sensor metrics based on the provided sensor IDs,
 * measurement types, and time range. The result is returned in a formatted structure.
 *
 * @param {string[]} sensor_ids - An array of sensor IDs for which metrics are to be fetched.
 * @param {MeasureTypeEnum[]} fieldTypes - An array of measurement types to filter the metrics.
 * @param {string} startRange - The start of the time range for the metrics (ISO 8601 format).
 * @param {string} endRange - The end of the time range for the metrics (ISO 8601 format).
 * @returns {Promise<any>} - A promise that resolves to the formatted metrics data.
 * @throws Will log an error to the console if the query fails.
 */
export async function getFormattedMetricsBySensorService(
  sensor_ids: string[],
  fieldTypes: MeasureTypeEnum[],
  startRange: string,
  endRange: string
): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSOR_METRICS_FORMATTED,
      variables: {
        filter: {
          sensor_ids,
          fieldTypes,
          startRange,
          endRange,
        },
      },
      fetchPolicy: 'network-only',
    });

    return data.getMetricsBySensorIdsFormattedMultiCurve;
  } catch (reason) {
    console.error(
      'An error occured while fetching metrics by sensor ID:',
      reason
    );
  }
}

/**
 * Renews the SIGFOX subscription for a given sensor.
 *
 * This function sends a mutation request to the GraphQL server to renew
 * the SIGFOX subscription for the sensor with the provided SIGFOX ID.
 *
 * @param {string} sigfoxId - The SIGFOX ID of the sensor for which to renew the subscription.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the subscription was successfully renewed.
 * @throws Will throw an error if the renewal fails.
 */
export async function renewSigfoxSubscriptionService(
  sigfoxId: string
): Promise<boolean> {
  try {
    const { data } = await client.mutate({
      mutation: RENEW_SIGFOX_SUBSCRIPTION,
      variables: { sigfoxId },
    });

    return data.restartAndActivateDevice;
  } catch (error) {
    console.error(
      `An error occurred while renewal the SIGFOX subscription for the sensor with sigfox id ${sigfoxId}:`,
      error
    );
    throw error;
  }
}

export async function createStartingCurve(
  sensorId: string,
  startAt: Date
): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_STARTING_CURVE,
      variables: {
        createCurveFittingInput: { sensor_id: sensorId, start_at: startAt },
      },
    });

    return data.createStartingCurve;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}

export async function clearStartingCurve(sensorId: string): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CLEAR_STARTING_CURVE,
      variables: {
        clearStartingCurveInput: { sensor_id: sensorId },
      },
    });

    return data.clearStartingCurve;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}

/**
 * Fetches the first and last values for specified sensor and curve.
 *
 * @param sensor_id - The ID of the sensor to fetch values for.
 * @param curve_id - The ID of the curve to fetch values for, if any.
 * @param fieldTypes - The types of measurements to fetch values for.
 *
 * @returns A promise that resolves to an array of objects containing the first and last values for each measurement type.
 *
 * @throws An error if the query fails.
 */
export async function getFirstAndLastValues(
  sensor_id: string,
  curve_id: string | undefined,
  fieldTypes: MeasureTypeEnum[]
): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_FIRST_AND_LAST_VALUES,
      variables: { filter: { sensor_id, curve_id, fieldTypes } },
      fetchPolicy: 'network-only',
    });

    return data.getFirstAndLastValues;
  } catch (error) {
    console.error(
      'An error occured while fetching inital and last measure',
      error
    );
    throw error;
  }
}
