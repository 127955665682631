import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
  query GetProjects {
    projects {
      id
      created_at
      created_by
      updated_at
      updated_by
      name
      type
      postal_code
      country
      longitude
      latitude
      reference
      image
      image_url
      tmodules {
        id
        reference
        name
        type
        sensors {
          id
          name
          active
        }
      }
    }
  }
`;

export const FIND_ALL_USER_PROJECTS = gql`
  query findAllUserProject(
    $orderByField: String! = "created_at"
    $orderByOrder: String! = "ASC"
  ) {
    findAllUserProject(
      orderByField: $orderByField
      orderByOrder: $orderByOrder
    ) {
      id
      created_at
      created_by
      updated_at
      updated_by
      name
      type
      postal_code
      country
      longitude
      latitude
      reference
      image
      image_url
      tmodules {
        id
        reference
        name
        type
        sensors {
          id
          name
          active
          family
        }
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($createProjectInput: CreateProjectInput!) {
    createProject(createProjectInput: $createProjectInput) {
      id
      created_at
      created_by
      updated_at
      updated_by
      name
      type
      postal_code
      country
      longitude
      latitude
      reference
      image
    }
  }
`;

export const CREATE_MODULE_MUTATION = gql`
  mutation CreateModule($createModuleInput: CreateTModuleInput!) {
    createModule(createModuleInput: $createModuleInput) {
      id
      reference
      name
      type
      parameters
      sensors {
        id
        measurement_interval
        sensor_type
        name
        image
        full_memory_handler_type
        vibration_alerte_type
        memory
        family
        version_firmware
        thresholds_active_type
        id_device
        active
        optional_name
      }
    }
  }
`;

export const CREATE_SENSOR_MUTATION = gql`
  mutation CreateSensor($createSensorInput: CreateSensorInput!) {
    createSensor(createSensorInput: $createSensorInput) {
      id
      measurement_interval
      sensor_type
      name
      image
      full_memory_handler_type
      vibration_alerte_type
      memory
      family
      version_firmware
      thresholds_active_type
      id_device
      active
      optional_name
    }
  }
`;

export const FIND_PROJECT_BY_ID = gql`
  query FindProjectUser($projectId: String!) {
    findProjectUser(projectId: $projectId) {
      id
      created_at
      created_by
      updated_at
      updated_by
      name
      type
      postal_code
      country
      longitude
      latitude
      reference
      image
      image_url
      tmodules {
        id
        created_at
        created_by
        updated_at
        updated_by
        reference
        name
        type
        parameters
        sensors {
          id
          created_at
          created_by
          updated_at
          updated_by
          measurement_interval
          sensor_type
          name
          image
          image_url
          full_memory_handler_type
          vibration_alerte_type
          memory
          family
          version_firmware
          thresholds_active_type
          id_device
          active
          optional_name
          curve {
            id
            last_deleted_date
            created_at
            deleted_at
            updated_at
            curve_fittings {
              start_at
            }
          }
        }
      }
      plans {
        id
        created_at
        name
        image_url
      }
    }
  }
`;

export const FIND_ALL_PROJECT_SENSORS = gql`
  query FindAllProjectSensors($projectId: String!) {
    findAllProjectSensor(projectId: $projectId) {
      id
      created_at
      created_by
      updated_at
      updated_by
      measurement_interval
      sensor_type
      name
      image
      image_url
      full_memory_handler_type
      vibration_alerte_type
      memory
      family
      version_firmware
      thresholds_active_type
      id_device
      project_id
      active
      optional_name
    }
  }
`;

export const FIND_PROJECT_THRESHOLD_HISTORY_BY_ID = gql`
  query ThresholdHistory($thresholdHistoryInput: GetHistoryThresholdInput!) {
    thresholdHistory(thresholdHistoryInput: $thresholdHistoryInput) {
      history {
        id
        value
        firstValueBrut
        delta
        type
        sensorId
        triggered_at
        projectId
        sensor {
          name
          id_device
        }
      }
      count
    }
  }
`;

export const GET_SHARED_USERS = gql`
  query GetSharedUser($projectId: String!) {
    getSharedUser(projectId: $projectId) {
      user_id
      accessType
    }
  }
`;
